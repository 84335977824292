<template>
    <div class="page_content">

        <div class="page_edit_content">
            <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 8 }">
                <a-form-item required label="规则名称">
                    <a-input placeholder='请填写规则名称' v-model:value="form.name" />
                </a-form-item>
                <a-form-item required label="晚班设置">
                    <a-radio-group v-model:value="form.is_open_night">
                        <a-radio :value="1">启用晚班</a-radio>
                        <a-radio :value="0">不启用晚班</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item required label="旺季设置">
                    <a-radio-group v-model:value="form.is_busy_season">
                        <a-radio :value="1">设定旺季</a-radio>
                        <a-radio :value="0">不设定旺季</a-radio>
                    </a-radio-group>
                </a-form-item>

                <a-form-item required label="旺季时间" v-if='form.is_busy_season==1'>
                    <a-select ref="select" mode="multiple" v-model:value="form.busy_season_time">
                        <a-select-option :value="month" v-for='month in 12' :key='month'>{{month}}月</a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item required label="旺季白班合格时长" v-if='form.is_busy_season==1'>
                    <a-input type="number" @input="handleInput('busy_day_pass_time')" suffix='小时' v-model:value="form.busy_day_pass_time" />
                </a-form-item>

                <a-form-item required v-if='form.is_busy_season==1&&form.is_open_night==1' label="旺季晚班合格时长">
                    <a-input type="number" @input="handleInput('busy_night_pass_time')" suffix='小时' v-model:value="form.busy_night_pass_time" />
                </a-form-item>

                <a-form-item required :label="form.is_busy_season==1?'淡季白班合格时长':'白班合格时长'">
                    <a-input type="number" @input="handleInput('day_pass_time')" suffix='小时' v-model:value="form.day_pass_time" />
                </a-form-item>
                <a-form-item v-if='form.is_open_night==1' required :label="form.is_busy_season==1?'淡季晚班合格时长':'晚班合格时长'">
                    <a-input type="number" @input="handleInput('night_pass_time')" suffix='小时' v-model:value="form.night_pass_time" />
                </a-form-item>


                <a-form-item required label="旺季白班执行时间" v-if='form.is_busy_season==1'>
                    <a-time-picker style='width:49%' v-model:value="form.busy_day_start_time" valueFormat="HH:mm:ss" />~
                    <a-time-picker style='width:49%' v-model:value="form.busy_day_end_time" valueFormat="HH:mm:ss" />
                </a-form-item>
                <a-form-item required :label="form.is_busy_season==1?'淡季白班执行时间':'白班执行时间'">
                    <a-time-picker style='width:49%' v-model:value="form.day_start_time" valueFormat="HH:mm:ss" />~
                    <a-time-picker style='width:49%' v-model:value="form.day_end_time" valueFormat="HH:mm:ss" />
                </a-form-item>

                <a-form-item required label="晚班执行时间" v-if='form.is_open_night==1 && form.is_busy_season != 1'>
                    <a-time-picker style='width:49%' v-model:value="form.night_start_time" valueFormat="HH:mm:ss" />~
                    <a-time-picker style='width:49%' v-model:value="form.night_end_time" valueFormat="HH:mm:ss" />
                </a-form-item>
                <a-form-item required label="旺季晚班执行时间" v-if='form.is_open_night==1 && form.is_busy_season == 1'>
                    <a-time-picker style='width:49%' v-model:value="form.busy_night_start_time"
                        valueFormat="HH:mm:ss" />~
                    <a-time-picker style='width:49%' v-model:value="form.busy_night_end_time" valueFormat="HH:mm:ss" />
                </a-form-item>
                <a-form-item required label="淡季晚班执行时间" v-if='form.is_open_night==1 && form.is_busy_season == 1'>
                    <a-time-picker style='width:49%' v-model:value="form.night_start_time" valueFormat="HH:mm:ss" />~
                    <a-time-picker style='width:49%' v-model:value="form.night_end_time" valueFormat="HH:mm:ss" />
                </a-form-item>
                <a-form-item required label="节假日打卡">
                    <a-radio-group v-model:value="form.is_join_record_holiday">
                        <a-radio :value="1">记录合格天数</a-radio>
                        <a-radio :value="0">不记录合格天数</a-radio>
                    </a-radio-group>
                </a-form-item>


                <a-form-item label="合格天数" v-if="!form.is_busy_season == 1">
                    <a-input type="number" @input="handleInput('pass_num')" suffix='天' v-model:value="form.pass_num" />
                </a-form-item>

                <a-form-item label="淡季合格天数" v-if="form.is_busy_season == 1">
                    <a-input type="number" @input="handleInput('pass_num')" suffix='天' v-model:value="form.pass_num" />
                </a-form-item>
                <a-form-item label="旺季合格天数" v-if="form.is_busy_season == 1">
                    <a-input type="number" @input="handleInput('busy_pass_num')" suffix='天' v-model:value="form.busy_pass_num" />
                </a-form-item>

                <a-form-item required label="晚班应执行天数" v-if='form.is_open_night==1 && !form.is_busy_season == 1'>
                    <a-input type="number" @input="handleInput('night_pass_num')" suffix='天' v-model:value="form.night_pass_num" />
                </a-form-item>
                <a-form-item required label="淡季晚班应执行天数" v-if='form.is_open_night==1 && form.is_busy_season == 1'>
                    <a-input type="number" @input="handleInput('night_pass_num')" suffix='天' v-model:value="form.night_pass_num" />
                </a-form-item>
                <a-form-item required label="旺季晚班应执行天数" v-if='form.is_open_night==1 && form.is_busy_season == 1'>
                    <a-input type="number" @input="handleInput('busy_night_pass_num')" suffix='天' v-model:value="form.busy_night_pass_num" />
                </a-form-item>
                <a-form-item label="免执行天数" v-if="!form.is_busy_season == 1">
                    <a-input type="number" @input="handleInput('unneed_day')" suffix='天' v-model:value="form.unneed_day" />
                </a-form-item>
                <a-form-item label="淡季免执行天数" v-if="form.is_busy_season == 1">
                    <a-input type="number" @input="handleInput('unneed_day')" suffix='天' v-model:value="form.unneed_day" />
                </a-form-item>
                <a-form-item label="旺季免执行天数" v-if="form.is_busy_season == 1">
                    <a-input type="number" @input="handleInput('busy_unneed_day')" suffix='天' v-model:value="form.busy_unneed_day" />
                </a-form-item>
                <!-- <a-form-item required label="白班执行时间">
					<a-time-picker style='width:49%' v-model:value="form.day_start_time"
						valueFormat="HH:mm:ss" />~<a-time-picker style='width:49%' v-model:value="form.day_end_time"
						valueFormat="HH:mm:ss" />
				</a-form-item>
				<a-form-item required label="晚班执行时间">
					<a-time-picker style='width:49%' v-model:value="form.night_start_time"
						valueFormat="HH:mm:ss" />~<a-time-picker style='width:49%' v-model:value="form.night_end_time"
						valueFormat="HH:mm:ss" />
				</a-form-item>
 -->
                <a-form-item label="交接班打卡">
                    <a-radio-group v-model:value="form.is_need_handover">
                        <a-radio :value="1">是</a-radio>
                        <a-radio :value="0">否</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="状态">
                    <a-switch v-model:checked="status" />
                </a-form-item>
                <a-form-item label="规则适用人员">
                    <TransferTree @change="TransferTreeChange" :treeData.sync="treeData" :value="treeDataValue" />
                </a-form-item>

                <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                    <a-button type="primary" html-type="submit" @click='handleSubmit'>
                        保存
                    </a-button>
                </a-form-item>
            </a-form>


            <!-- 	<Form ref="form" :form_data="form_data" @handleSubmit="handleSubmit">
				<template slot="status">
					<a-switch v-model:checked="status" />
				</template>
				<template slot="rule_user">
					
					<a-input hidden="true" v-decorator="['rule_user']"></a-input>
				</template>
				<template slot="day">
					
				</template>
				<template slot="night">
					
				</template>
			</Form> -->
        </div>
    </div>
</template>

<script>
    import Form from "@/components/Form";
    import {
        message
    } from 'ant-design-vue'
    import {
        post_user
    } from '@/api/api'
    import TransferTree from "@/components/TransferTreeTwo";
    import {
        saveRuleInfo,
        getRuleDetail,
    } from "@/api/supervision";
    export default {
        components: {
            Form,
            TransferTree
        },
        data() {
            return {
                night_end_time: '',
                day_start_time: '',
                day_end_time: '',
                night_start_time: '',
                treeData: [],
                rule_user: [],
                treeDataValue: [],
                expand: false,
                loading: true,
                status: false,
                datas: {},
                form: {

                },
                form_data: {
                    title: "基本信息",
                    show_submit_btn: true,
                    show_close_btn: true,
                    close_reset_btn: true,
                    list: [{
                            type: 'text',
                            name: 'id',
                            options: {
                                initialValue: '',

                            },
                            hidden: true
                        },
                        {
                            required: true,
                            type: 'text',
                            name: 'name',
                            title: '名称',
                            options: {
                                required: true,
                                message: "请填写名称",
                                initialValue: ''
                            },
                        },
                        {
                            type: 'text',
                            name: 'day_pass_time',
                            placeholder: '请输入',
                            required: true,
                            title: '白班合格时长',
                            suffix: "小时",
                            options: {
                                required: true,
                                message: "请填写白班合格时长",
                                initialValue: ''
                            },
                        },
                        {
                            type: 'text',
                            name: 'night_pass_time',
                            placeholder: '请输入',
                            required: true,
                            title: '夜班合格时长',
                            suffix: "小时",
                            options: {
                                required: true,
                                message: "请填写夜班合格时长",
                                initialValue: ''
                            },
                        },
                        {
                            type: 'text',
                            name: 'pass_num',
                            placeholder: '请输入',
                            title: '合格天数',
                            suffix: "天",
                            options: {
                                message: "请填写晚班应执行天数",
                                initialValue: ''
                            },

                        },
                        {
                            type: 'text',
                            name: 'night_pass_num',
                            placeholder: '请输入',
                            title: '晚班应执行天数',
                            required: true,
                            suffix: "天",
                            options: {
                                required: true,
                                message: "请填写晚班应执行天数",
                                initialValue: ''
                            },
                        },
                        {
                            type: 'text',
                            name: 'unneed_day',
                            placeholder: '请输入',

                            title: '免执行天数',
                            suffix: "天",
                            options: {
                                initialValue: ''
                            },
                        },
                        {
                            type: 'slot',
                            name: 'day',
                            placeholder: '请输入',
                            title: '白班执行时间',
                            required: true,
                            options: {
                                required: true,
                                message: "请输入白班执行时间"
                            },
                        },
                        {
                            type: 'slot',
                            name: 'night',
                            placeholder: '请输入',
                            required: true,
                            title: '晚班执行时间',
                            options: {
                                required: true,
                                message: "请输入晚班执行时间"
                            },
                        },
                        {
                            type: 'slot',
                            name: 'status',
                            placeholder: '请选择',
                            title: '状态',
                            options: {},
                            list: [{
                                key: 1,
                                value: '启用'
                            }, {
                                key: -1,
                                value: '停用'
                            }],
                        },
                        {
                            type: 'slot',
                            name: 'rule_user',
                            title: '添加人员',
                            options: {},
                        }
                    ]
                },

            };
        },
        created() {
            post_user().then(res => {
                this.treeData = this.$method.get_user_department_list(res.data.list,
                    'sysUserTree')

                this.$nextTick(() => {
                    if (this.$route.query.id) {
                        this.edit(this.$route.query)
                    }
                })

            })

        },
        methods: {
            TransferTreeChange(value) {
                this.rule_user = value.join(',')
            },
            edit(record) {
                getRuleDetail({
                    data: {
                        id: record.id
                    }
                }).then(res => {
                    let data = res.data.list


                    let treeDataValue = [];
                    data.rule_user.map(io => {
                        treeDataValue.push("user_" + io.id)
                        io.key = "user_" + io.id;
                        io.title = io.username;
                        return io;
                    });
                    this.treeDataValue = JSON.parse(JSON.stringify(treeDataValue));
                    this.$forceUpdate()
                    this.$forceUpdate()
                    this.form = data
                    this.form.busy_season_time = data.busy_season_time.split(',').map(it => {
                        return Number(it)
                    }).filter(item => item != 0)
                    this.status = data.status == 1 ? true : false
                })
            },
            handleSubmit(e) {
                let data = JSON.parse(JSON.stringify(this.form));
                if (!data.id) {
                    data.id = '';
                }
                if (data.unneed_day * 1 > data.pass_num * 1 && data.is_busy_season != 1) {
                    message.error('免执行天数应小于等于合格天数');
                    return false
                }
                if (data.unneed_day * 1 > data.pass_num * 1 && data.is_busy_season == 1) {
                    message.error('淡季免执行天数应小于等于淡季合格天数');
                    return false
                }
                if (data.busy_unneed_day * 1 > data.busy_pass_num * 1 && data.is_busy_season == 1) {
                    message.error('旺季免执行天数应小于等于旺季合格天数');
                    return false
                }
                data.busy_season_time = data.is_busy_season == 1 ? data.busy_season_time.join(',') : ''
                saveRuleInfo({
                    data: {
                        ...data,
                        status: this.status ? 1 : -1,
                        rule_user: this.rule_user
                    },
                    info: true,
                }).then(res => {
                    this.$router.back()
                })
            },
            handleInput(fieldName){
                if (this.form[fieldName] < 0) {
                    this.form[fieldName] = 0;
                }
            }
        }
    };
</script>
<style lang="less" scoped>
    .map_select {
        position: relative;

        .serach_input {
            position: absolute;
            top: 20px;
            left: 20px;
        }
    }

    ul {
        padding: 0;
    }

    li {
        display: block;
        list-style: none;
        display: flex;

        span {
            padding-right: 10px;

            display: inline-block;
        }
    }

    .avatar-uploader>.ant-upload {
        width: 128px;
        height: 128px;
    }

    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

    .spin-content {
        border: 1px solid #91d5ff;
        background-color: #e6f7ff;
        padding: 30px;
    }

    .distributor {
        width: 60%;
        margin-left: 1.5%;
        padding: 5px 10px;
        padding-bottom: 10px;
    }

    .distributor_box {
        background-color: rgba(250, 250, 250, 1);
        margin-left: 14%;
        padding-bottom: 20px;

        .title {
            margin-left: 24px;
        }

        /deep/.ant-col-5 {
            width: 7%;
        }
    }

    .page_content .ant-col-19 {
        width: 34.166667% !important;
    }

    /deep/ .ant-input-affix-wrapper {
        .ant-input:not(:last-child) {
            padding-right: 45px;
        }
    }
</style>